.library {
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    height: 100%;
    box-shadow: 2px 2px 50px rgb(204,204,204);
    overflow: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    background-color: white;
    h2 {
        padding: 2rem;
    }
}

.library-song {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
    cursor: pointer;
    transition: background 0.3s ease;
    img {
        width: 30%;
    }
    &:hover {
        background: rgb(255, 241, 244);
    }
}

.song-description {
    padding-left: 1rem;
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: 0.7rem;
    }
}

.selected {
    background-color: rgb(252, 216, 222);
}

.active-library {
    transform: translateX(0%);
    opacity: 1;
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

@media screen and (max-width: 768px) {
    .library {
        width: 100%;
    }
  }