* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, 
h2 {
    color: rgb(54,54,54)
}

body {
    font-family: 'Lato', sans-serif;
}

h3,
h4 {
    font-weight: 400;
    color: rgb(100, 100, 100);
}

.App {
    transition: all 0.5s ease;
}

.library-active {
    margin-left: 20%;
}

@import "./player";
@import "./song";
@import "./library";
@import "./nav";

