.player {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .record-player-arm-active-lib {
        transition: all 0.5s ease;
        transition-property: width, top, right, transform;
        position: absolute;
        right: 30%;
        top: 2%;
        border-radius: 0%;
        width: 8%;
    }
    .record-player-arm {
        transition: all 1s ease;
        transition-property: width, top, right, transform;
        position: absolute;
        right: 35%;
        top: 1%;
        border-radius: 0%;
        width: 10%;
    }
}

.song-control {
    width: 50%;
    display: flex;
    align-items: center;
    input {
        width: 100%;
        
        cursor: pointer;
        -webkit-appearance: none;
        background-color: transparent;
    }
    p {
        padding: 1rem;
    }
}

.play-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        width: 30%;
        svg {
            cursor: pointer;
        }
    
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
}

// firefox fix
input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    background: transparent;
    border: none;
}

.track {
    width: 100%;
    height: 1rem;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
}

.animate-track {
    width: 100%;
    height: 100%;
    background: rgb(204,204,204);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}


@media screen and (max-width: 1100px) {
    .player {
        .song-control{
            width: 90%;
        }
        .play-control {
            width: 70%;
        }
        .record-player-arm {
            position: absolute;
            right: 14%;
            top: 13%;
            border-radius: 0%;
            width: 20%;
        }
    }
}