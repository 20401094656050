.song-container {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .album-cover {
        width: 20%;
        border-radius: 50%;
    }
    h2 {
        padding: 3rem 1rem 1rem 1rem;
    }
    h3 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1100px) {
    .song-container{
        .album-cover {
            width: 50%;
        }
    }
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .album-cover.spinning {
    animation: spin 5s linear infinite;
  }